<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>医患管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/patientManage' }"
        >注册患者</el-breadcrumb-item
      >
      <el-breadcrumb-item>浏览足迹</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs v-model="activeName">
      <el-tab-pane label="近30日浏览足迹" name="first">
        <div style="height: 900px; overflow-y: auto">
          <div v-for="(item, index) in list" class="line" :key="index">
            <p>{{ item.label }}</p>
            <el-row class="footcard">
              <el-col :span="3" v-for="(it, ind) in item.value" :key="ind">
                <el-card
                  class="cardbox"
                  v-if="it.type == 1"
                  :body-style="{ padding: '0px' }"
                >
                  <p class="label green">疾病</p>
                  <p class="image">
                    <img :src="JSON.parse(it.content).pic" fit="contain" />
                  </p>
                  <div style="padding: 14px">
                    <span class="name">{{ JSON.parse(it.content).name }}</span>
                    <span class="time">{{ it.create_time.split(".")[0] }}</span>
                  </div>
                </el-card>
                <el-card
                  class="cardbox"
                  v-if="it.type == 2"
                  :body-style="{ padding: '0px' }"
                >
                  <p class="label blue">医生</p>
                  <p class="image">
                    <img :src="JSON.parse(it.content).headimg" fit="contain" />
                  </p>
                  <div style="padding: 14px">
                    <span class="name">{{ JSON.parse(it.content).name }}</span>
                    <span class="time">{{ it.create_time.split(".")[0] }}</span>
                  </div>
                </el-card>
                <el-card
                  class="cardbox"
                  v-if="it.type == 3"
                  :body-style="{ padding: '0px' }"
                >
                  <p class="label orange">视频</p>
                  <p class="image">
                    <img :src="JSON.parse(it.content).pic" fit="contain" />
                  </p>
                  <div style="padding: 14px">
                    <span class="name">{{ JSON.parse(it.content).title }}</span>
                    <span class="time">{{ it.create_time.split(".")[0] }}</span>
                  </div>
                </el-card>
                <el-card
                  class="cardbox"
                  v-if="it.type == 4"
                  :body-style="{ padding: '0px' }"
                >
                  <p class="label red">商品</p>
                  <p class="image">
                    <img :src="JSON.parse(it.content).thumb" fit="contain" />
                  </p>
                  <div style="padding: 14px">
                    <span class="name">{{ JSON.parse(it.content).name }}</span>
                    <span class="time">{{ it.create_time.split(".")[0] }}</span>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      custid: this.$route.query.id,
      list: [],
      activeName: "first",
    };
  },
  mounted() {
    this.getdetail(this.custid);
  },
  methods: {
    getdetail(id) {
      //type：1：疾病；2：医生；3：视频；4：商品
      this.axios
        .get("/tracking/get_gu_tracking_by_custid?cust_id=" + id)
        .then((res) => {
          if (res.data.code == 0) {
            var obj = res.data.result;
            for (var key in obj) {
              var str = { label: key, value: obj[key] };
              this.list.push(str);
            }
            console.log(this.list);
          }
        });
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
.image {
  width: 150px;
  height: 150px;
  margin: 0;
}
.image img {
  width: 150px;
  height: 150px;
}
.line {
  text-align: left;
  font-size: 14px;
}
.footcard {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.footcard .el-col {
  margin-bottom: 10px;
  width: 150px;
  margin-right: 10px;
  align-items: stretch;
}
.cardbox {
  position: relative;
}
.label {
  height: 30px;
  color: #fff;
  /* background: #000; */
  position: absolute;
  top: 8px;
  left: 0;
  z-index: 5;
  margin: 0;
  width: 60px;
  line-height: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding-left: 12px;
  box-sizing: border-box;
}
.blue {
  background: #409eff;
}
.green {
  background: #67c23a;
}
.orange {
  background: #e6a23c;
}
.red {
  background: #ff5151;
}
.name {
  width: 100%;
  display: inline-block;
  margin-bottom: 8px;
}
.time {
  font-size: 10px;
  width: 100%;
  display: inline-block;
  color: #909399;
}
</style>